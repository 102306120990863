import React, { useCallback, useMemo } from "react"
import { CircularProgress, Divider, Drawer } from "@mui/material"
import { useSectionItemsQueryFn, useTenantTasksQueryFn } from "../../../../queries/kanban"
import TaskDetailsHeader from "./TaskDetailsHeader"
import TaskDetailsCommentInput from "../TaskDetailsCommentInput"
import DetailsContent from "../DetailsContent"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { AppContext, useIsMobile } from "rentzz"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export default function DesktopTaskDetails() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { editingItem, modalOpen, context } = useSelector((state) => state.appState)
    const { data: ownerTasks } = useSectionItemsQueryFn(editingItem?.sectionId)
    const { data: tenantTasks } = useTenantTasksQueryFn(editingItem?.rentingPeriodId)
    const closeOnBackdrop = useFeatureIsOn("close-on-backdrop-click")

    const tasks = useMemo(() => (context === AppContext.Owner ? ownerTasks : tenantTasks), [context, ownerTasks, tenantTasks])

    const task = useMemo(() => tasks?.find((t) => t.id === editingItem?.taskId), [tasks, editingItem])

    const onCloseDetails = useCallback(
        (_: unknown, reason: string) => {
            if (reason === "backdropClick" && !closeOnBackdrop) {
                return
            }
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        },
        [dispatch, closeOnBackdrop],
    )

    return (
        <Drawer
            open={!isMobile && modalOpen === AppModals.TaskDetails}
            onClose={onCloseDetails}
            anchor='right'
            slotProps={{
                backdrop: { invisible: true },
            }}
            PaperProps={{
                sx: {
                    width: {
                        xs: 1,
                        sm: 480,
                    },
                },
            }}
        >
            {task == null && <CircularProgress />}
            {task != null && (
                <>
                    {context === AppContext.Owner && (
                        <>
                            <TaskDetailsHeader sectionId={task.sectionId} taskId={task.id} onClose={() => onCloseDetails(null, "")} />
                            <Divider />
                        </>
                    )}

                    <DetailsContent task={task} />
                    {(context === AppContext.Owner ? task?.canEdit : true) && (
                        <>
                            <Divider />
                            <TaskDetailsCommentInput taskId={task.id} sectionId={task.sectionId} />
                        </>
                    )}
                </>
            )}
        </Drawer>
    )
}
